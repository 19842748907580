<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <CText class="px-2">Asia/Singapore (GMT +8)</CText>
        <span
          class="avatar-name"
          :style="{
            color: utils.PickColorFromString(profile.name),
          }"
          >{{ profile.name }}</span
        >
        <CImg
          v-bind:src="profile.photo_url || 'img/no-avatar.png'"
          block
          width="40px"
          height="40px"
          class="mb-2"
          shape="rounded"
        />
      </CHeaderNavLink>
    </template>

    <CDropdownItem v-on:click="onProfile">
      <CIcon name="cil-user" /> Profile
    </CDropdownItem>
    <CDropdownItem v-on:click="onSetting">
      <CIcon name="cil-settings" /> Settings
    </CDropdownItem>

    <CDropdownDivider />
    <CDropdownItem v-on:click="onLogout">
      <CIcon name="cil-lock-locked" /> Logout
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import { ACTION_LOGOUT } from '@/store/actions.type';
import { mapGetters } from 'vuex';

export default {
  name: 'TheHeaderDropdownAccnt',
  data() {
    return {};
  },

  computed: {
    ...mapGetters(['profile']),
  },
  methods: {
    onProfile: function () {
      const path = `/profile`;
      if (this.$route.path !== path) this.$router.push(path);
    },
    onSetting: function () {
      const path = `/setting`;
      if (this.$route.path !== path) this.$router.push(path);
    },
    onLogout: function () {
      this.$store.dispatch(ACTION_LOGOUT).then(() => {
        this.$router.push({ name: 'login.index' });
      });
    },
  },
};
</script>

<style scoped lang="scss">
.avatar-name {
  margin-right: 10px;
  font-weight: bold;
  text-align: center;
}
.c-icon {
  margin-right: 0.3rem;
}
</style>
